<template>
  <div>
    <loader v-if="loading"></loader>
    <section class="header">
      <h1>{{ isCreating ? "Создание теста" : "Все тесты" }}</h1>
      <v-switch
        v-model="isCreating"
        hide-details
        :label="isCreating ? 'Показать все тесты' : 'Перейти к созданию теста'"
        @change="isShowTest = false"
      ></v-switch>
    </section>
    <section v-if="isCreating && !isShowTest">
      <v-form ref="testForm" class="test-form">
        <v-text-field
          v-model.trim="input.videoUrl"
          type="text"
          label="Добавить ссылку на видео"
          class="header_input"
          :rules="[$validate.required]"
        ></v-text-field>
        <v-text-field
          v-model.trim="input.name"
          type="text"
          label="Название видео"
          class="header_input"
          :rules="[$validate.required]"
        ></v-text-field>
        <v-text-field
          v-model.trim="input.nameTest"
          type="text"
          label="Название теста"
          class="header_input"
          :rules="[$validate.required]"
        ></v-text-field>
        <v-select
          v-model="input.roleId"
          label="Для какой роли тест"
          :items="ROLE.allRoles"
          item-value="id"
          item-text="name"
          class="header_input"
          :rules="[$validate.required]"
        ></v-select>
        <v-select
          v-model="input.departmentId"
          label="Для какого филиала тест"
          :items="DEPARTMENT.cityDepartment"
          item-value="id"
          item-text="name"
          class="header_input"
          :rules="[$validate.required]"
        ></v-select>
      </v-form>
      <v-btn color="primary" @click="addVideoToServer()">Добавить</v-btn>
    </section>
    <section v-if="!isShowTest && !isCreating">
      <v-simple-table>
        <thead>
          <tr>
            <th class="text-left">Название теста</th>
            <th class="text-left">Название видео</th>
            <th class="text-left">Ссылка на видео</th>
            <th class="text-left">Для роли</th>
            <th class="text-left">Для филиала</th>
            <th class="text-left">Есть вопросы</th>
            <th class="text-left">Действия</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="item in EDUCATION" :key="item.id">
            <td>
              {{
                item.educationalTests.length
                  ? `${item.educationalTests[0].name}`
                  : "Не привязан тест"
              }}
            </td>
            <td>{{ item.name }}</td>
            <td>
              <a :href="item.videoUrl" target="_blank">{{ item.videoUrl }}</a>
            </td>
            <td>{{ item.role.name }}</td>
            <td>{{ item.department.name }}</td>
            <td v-if="item.educationalTests.length">
              {{
                item.educationalTests[0].educationalTestQuestions.length
                  ? "Да"
                  : "Нет"
              }}
            </td>
            <td v-else>Не привязан тест</td>
            <td>
              <v-btn color="primary" icon @click="editTest(item)"
                ><v-icon>mdi-pencil</v-icon></v-btn
              >
              <v-btn color="error" icon @click="showConfirmModal(item.id)"
                ><v-icon>mdi-trash-can</v-icon></v-btn
              >
            </td>
          </tr>
        </tbody>
      </v-simple-table>
    </section>
    <EditTest
      v-if="isShowTest"
      :test="test"
      @hide="isShowTest = false"
      @update="GET_ALL_VIDEO()"
    />
    <DeleteDialog
      :show="showDeleteModal"
      @close-modal="showDeleteModal = !showDeleteModal"
      @delete-modal="deleteTest()"
    />
  </div>
</template>
<script>
import Loader from "@/components/Loader";
// import ShowTest from "@/components/Education/ShowTest";
import EditTest from "@/components/Education/EditTest";
import DeleteDialog from "@/components/DeleteDialog";
import { mapGetters, mapActions } from "vuex";
import ShowMessage from "@/Functions/message";
import api from "@/api";

export default {
  components: {
    Loader,
    EditTest,
    DeleteDialog,
  },
  data() {
    return {
      loading: true,
      isCreating: false,
      input: {},
      test: {},
      isShowTest: false,
      showDeleteModal: false,
      deletedId: 0,
    };
  },
  computed: {
    ...mapGetters({
      EDUCATION: "Education/STATE",
      DEPARTMENT: "Department/STATE",
      ROLE: "Role/STATE",
    }),
  },
  async mounted() {
    let promisArr = [];
    promisArr.push(this.GET_ALL_ROLES());
    promisArr.push(this.GET_ALL_VIDEO());
    await Promise.allSettled(promisArr);
    this.loading = false;
  },
  methods: {
    ...mapActions({
      GET_ALL_VIDEO: "Education/GET_ALL_VIDEO",
      GET_DEPARTMENT: "Department/GET_DEPARTMENT",
      GET_ALL_ROLES: "Role/GET_ALL_ROLES",
    }),
    // создаем тест, потом создаем видео, потом связываем тест и видео
    async addVideoToServer() {
      if (!this.$refs.testForm.validate()) {
        ShowMessage("Заполните все поля");
        return;
      }
      this.loading = true;
      this.input.position = 0;
      const testId = await api.Education.addTest(this.input.nameTest);
      if (testId.type === "error") {
        ShowMessage(testId.data.message);
        return;
      }
      this.input.educationalTestId = testId.id;
      const videoResp = await api.Education.addVideo(this.input);
      if (videoResp.type === "error") {
        ShowMessage(videoResp.data.message);
        return;
      }
      const bindResp = await api.Education.bindTestToVideo({
        testId: testId.id,
        videoId: videoResp.id,
      });
      if (bindResp.type === "error") {
        ShowMessage(bindResp.data.message);
        return;
      }
      this.GET_ALL_VIDEO();
      this.isCreating = false;
      ShowMessage("Тест и видео успешно созданы", true);
      this.loading = false;
    },
    editTest(test) {
      this.test = test;
      this.isShowTest = true;
    },
    showConfirmModal(id) {
      this.deletedId = id;
      this.showDeleteModal = true;
    },
    async deleteTest() {
      this.loading = true;
      const response = await api.Education.deleteVideo(this.deletedId);
      if (response.type === "error") {
        ShowMessage(bindResp.data.message);
        this.loading = false;
        return;
      }
      this.GET_ALL_VIDEO();
      this.showDeleteModal = false;
      this.loading = false;
    },
  },
};
</script>
<style lang="scss" scoped>
.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  &_input {
    max-width: 300px;
  }
}
</style>
